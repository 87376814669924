import { defaultsDeep } from "lodash-es";
import { defineStore, BaseModel } from "./store.pinia";
import { api } from "../utils/feathers-api";
import { useProcessTemplateFieldTypes } from "./processTemplateFieldTypes.pinia";

const getValidationRules = (data) => {
  return defaultsDeep(
    { ...(data?.validation_rules ?? {}) },
    // Defaults
    {
      required: false,
      hidden: false,
      minimum_length: 0,
      maximum_length: 255,
      minimum_value: 0,
      maximum_value: 0,
    }
  );
};

export class ProcessTemplateField extends BaseModel {
  static instanceDefaults(data = null) {
    const getDefaultFieldType = () => {
      const processTemplateFieldTypesStore = useProcessTemplateFieldTypes();
      // Get the text field type by default
      return (
        processTemplateFieldTypesStore.items?.find(
          (item) => item.title === "text"
        )?.id ?? null
      );
    };

    return {
      id: data?.id ?? null,
      process_template_id: data?.process_template_id ?? null,
      title: data?.title ?? "",
      sequence: data?.sequence ?? 1,
      process_template_field_type_id:
        data?.process_template_field_type_id ?? getDefaultFieldType(),
      required: data?.required ?? false,
      hide_on_ticket: data?.hide_on_ticket ?? false,
      print_as_barcode: data?.print_as_barcode ?? true,
      active: data?.active ?? true,
      validation_rules: getValidationRules(data),
      created: data?.created ?? null,
      modified: data?.modified ?? null,
      created_by_id: data?.created_by_id ?? null,
      modified_by_id: data?.modified_by_id ?? null,
    };
  }

  static setupInstance(data) {
    if (data?.validation_rules !== undefined) {
      data.validation_rules = getValidationRules(data);
    }
    return data;
  }
}

const servicePath = "process-template-fields";
export const useProcessTemplateFields = defineStore({
  idField: "id",
  Model: ProcessTemplateField,
  clients: { api },
  servicePath,
  actions: {
    addProcessTemplateField(processTemplateId, sequence = 0) {
      const processTemplateField = new this.Model({
        process_template_id: processTemplateId,
        sequence,
      });
      return this.addToStore(processTemplateField);
    },
    getFieldType(item) {
      const processTemplateFieldTypesStore = useProcessTemplateFieldTypes();
      return (
        processTemplateFieldTypesStore.itemsById[
          item.process_template_field_type_id
        ]?.title ?? null
      );
    },
  },
});

api.service(servicePath).hooks({});
