<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import { useFind } from "feathers-pinia";
import { useGetHelper } from "../../utils/feathers-pinia/use-get-helpers";
import { computedRef } from "../../utils/composables";
import { useOperation } from "../../store/operation.pinia";
import { useOperationDetail } from "../../store/operationDetail.pinia";
import { useProcessTemplateFields } from "../../store/processTemplateFields.pinia";
import { useProcessTemplates } from "../../store/processTemplates.pinia";
import { useWorkcenters } from "../../store/workcenters.pinia";
// import { i18n } from "../../i18n";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { sortBy } from "lodash-es";

export default {
  name: "OperationAccordion",
  props: {
    node: {
      default: () => null,
    },
    paneInfo: {
      default: () => null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("OperationAccordion.vue");
    const operationStore = useOperation();
    const operationDetailStore = useOperationDetail();
    const processDetailStore = useProcessTemplateFields();
    const processStore = useProcessTemplates();
    const workcenterStore = useWorkcenters();

    const operationId = computed(() => props.node?.regarding_object_id ?? null);
    const { item: operationResult, isPending: isPendingOperation } =
      useGetHelper(operationId, operationStore);

    const processId = computed(() => operationResult.value?.process_id ?? null);
    const { item: processResult, isPending: isPendingProcess } = useGetHelper(
      processId,
      processStore
    );

    const workcenterId = computed(
      () => processResult.value?.workcenter_id ?? null
    );
    const { item: workcenterResult, isPending: isPendingWorkcenter } =
      useGetHelper(workcenterId, workcenterStore);

    const operationDetailQuery = computedRef(() => {
      return {
        query: {
          operation_id: operationResult.value?.id,
        },
      };
    });
    const {
      items: operationDetailResults,
      isPending: isPendingOperationDetails,
    } = useFind({
      model: operationDetailStore.Model,
      params: operationDetailQuery,
      queryWhen: computed(
        () => !!operationDetailQuery.value?.query.operation_id
      ),
    });

    const processDetailIds = computed(() => {
      return (
        operationDetailResults.value?.map((item) => item.process_detail_id) ??
        []
      );
    });
    const processDetailsQuery = computedRef(() => {
      return {
        query: {
          id: { $in: processDetailIds.value },
        },
      };
    });
    const { items: processDetailsResults, isPending: isPendingProcessDetails } =
      useFind({
        model: processDetailStore.Model,
        params: processDetailsQuery,
        queryWhen: computed(
          () => !!processDetailsQuery.value?.query.id.$in?.length
        ),
      });

    const convertedDates = computed(() => {
      return {
        date_in: operationResult.value?.date_in?.split("T")[0],
        date_out: operationResult.value?.date_out?.split("T")[0],
      };
    });

    const sortedProcessDetailsResults = computed(() => {
      return sortBy(processDetailsResults.value, ["sequence"]);
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        operation: isPendingOperation.value,
        operationDetails: isPendingOperationDetails.value,
        processDetails: isPendingProcessDetails.value,
        process: isPendingProcess.value,
        workcenter: isPendingWorkcenter.value,
        operationDates: isPendingOperation.value,
        arrayItem: isPendingOperation.value || isPendingProcessDetails.value,
      };
    });

    const entities = computed(() => ({
      operation: operationResult.value,
      operationDetails: operationDetailResults.value, // arrayItem
      processDetails: sortedProcessDetailsResults.value, // arrayItem
      process: processResult.value,
      workcenter: workcenterResult.value,
      operationDates: convertedDates.value,
    }));

    const fieldRows = computed(() => {
      return [
        entityRow("Operation", [
          entityRowField("process", "title", {
            label: "Process Description",
            type: "text",
          }),
          entityRowField("workcenter", "title", {
            label: "Workcenter Description",
          }),
          entityRowField("operation", "changed_by", {
            label: "Operation Changed By",
          }),
          entityRowField("operationDates", "date_in", {
            label: "Operation Date In",
          }),
          entityRowField("operationDates", "date_out", {
            label: "Operation Date Out",
          }),
        ]),
        entityRow(
          "Operation Details",
          [
            entityRowField("arrayItem", "detail", {
              label: (field, entities, arrayItem) => {
                return (
                  entities.processDetails.find(
                    (item) => item.id === arrayItem.process_detail_id
                  )?.title ?? "[Process Detail Not Found]"
                );
              },
              colAttributes: {
                cols: 12,
                sm: 12,
                class: "px-2",
              },
            }),
          ],
          {
            // Specify rowType as array
            rowType: "array",
            // Path in entities to loop through
            arrayPath: "operationDetails",
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
